// Import React
import React from 'react';

// Import Data
import { PrudenteOffice, VenceslauOffice, SaoJoseOffice, SaoPauloOffice } from '../../data/contact';

// Import CSS
import './Contact.css'

// Import Components
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import OfficeData from '../../components/OfficeData/OfficeData';
import ContactForm from '../../components/Form/Form';

function Contact() {
    return (
        <section id="contact">
            <div className="custom-content">
                <div className="content padding responsive">
                    <SectionTitle
                        arrows="right"
                        title="Unidades"
                        subtitle="Nosso time espera por você"
                        reverse={true}
                    />

                    <div className="data-and-form">
                        <div>
                            <OfficeData
                                title={'Presidente Prudente'}
                                details={PrudenteOffice}
                            />

                            <OfficeData
                                title={'Presidente Venceslau'}
                                details={VenceslauOffice}
                            />

                            <OfficeData
                                title={'São José do Rio Preto'}
                                details={SaoJoseOffice}
                            />

                            <OfficeData
                                title={'São Paulo'}
                                details={SaoPauloOffice}
                            />
                        </div>
                    </div>
                </div>

                <div className="custom-contact-content">
                    <SectionTitle
                        arrows="right"
                        title="Contato"
                        subtitle="Fale com nossos especialistas"
                        reverse={true}
                    />

                    <ContactForm />
                </div>
            </div>
        </section>
    );
}

export default Contact;